import commonHelper from '@/app/utility/common.helper.utility';
import DefineProject from '../defineProjects';
import ProjectTowers from '../projectTower';
import ProjectFloorDetails from './projectFloorDetails';
import { required } from 'vuelidate/lib/validators';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'ProjectFloor',
  components: {
    DefineProject,
    ProjectTowers,
    ProjectFloorDetails
  },
  props: {
    showFloorComponent: Boolean,
    projecName: String,
    projectId: Number,
    towerName: String,
    towerId: Number
  },
  watch: {
    currentPage: function() {
      this.getProjectsFloorList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getProjectsFloorList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      parent_value_set_id: null,
      showModalComponent: false,
      showValueSetModal: false,
      loader: false,
      editMode: false,
      showAlert: false,
      payload: {},
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      tab: null,
      project: {
        value: null,
        text: null
      },
      tower: {
        value: null,
        text: null
      },
      floorCode: null,
      floorName: null,
      parentProjectFloorData: null,
      projectFloorData: [],
      projectFloorFields: [
        {
          key: 'project_id',
          class: 'd-none'
        },
        {
          key: 'project_name'
        },
        {
          key: 'project_tower_id',
          class: 'd-none'
        },
        {
          key: 'tower_name'
        },
        {
          key: 'project_floor_id',
          class: 'd-none'
        },
        {
          key: 'floor_code'
        },
        {
          key: 'floor_name'
        },
        {
          key: 'floor_type_vset',
          class: 'd-none'
        },
        {
          key: 'project_floor_type',
          label: 'Floor Type'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'version'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'sequence'
        }
      ]
    };
  },
  validations: {
    project: {
      text: {
        required
      }
    },
    tower: {
      text: {
        required
      }
    }
  },
  mounted() {
    if (this.showFloorComponent) {
      this.project.text = this.projecName;
      this.tower.text = this.towerName;
      this.getProjectsFloorList(this.towerId);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.hideModalComponent(true, 'ProjectFloorDetails');
          this.parentProjectFloorData = null;
        }
        if (actionName === 'download' && !this.showModalComponent) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'projects/getProjectsFloorList',
            'project-floor',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ProjectFloorDetails') {
        return (this.childName = 'Project Floor Details');
      } else if (tab === 'DefineProject') {
        return (this.childName = 'Define Project');
      } else if (tab === 'ProjectTowers') {
        return (this.childName = 'Project Towers');
      }
    },
    getProjectsFloorList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _limit: this.perPage,
          _page: this.currentPage - 1,
          tower_id: this.towerId ? this.towerId : this.tower.value,
          floor_code: this.floorCode,
          floor_name: this.floorName
        };
        this.loader = true;
        this.$store
          .dispatch('projects/getProjectsFloorList', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.projectFloorData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            } else {
              this.loader = false;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    hideModalComponent(flag, component) {
      if (component === 'ProjectTowers' && !this.project.text) {
        return alert(appStrings.DEPENDENTVSETMSG.PRJMASTERPRJMSG);
      }
      this.showModalComponent = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    rowSelected(rowData) {
      this.parentProjectFloorData = rowData;
      this.$emit('floorDetails', this.parentProjectFloorData);
      this.hideModalComponent(true, 'ProjectFloorDetails');
    },
    projectDetails(item) {
      this.showModalComponent = false;
      this.project = {
        value: item.project_id,
        text: item.project_name
      };
      this.tower = {
        value: null,
        text: null
      };
    },
    towerDetails(item) {
      this.showModalComponent = false;
      this.tower = {
        value: item.tower_id,
        text: item.tower_name
      };
    },
    updateProjectFloorList() {
      this.getProjectsFloorList();
    },
    resetSearchFilters() {
      this.project = {
        value: null,
        text: null
      };
      this.tower = {
        value: null,
        text: null
      };
      this.floorCode = null;
      this.floorName = null;
      this.projectFloorData = [];
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
