import DefineProject from '../../defineProjects';
import ProjectTowers from '../../projectTower';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'ProjectFloorDetails',
  components: {
    DefineProject,
    DatePicker,
    ProjectTowers
  },
  props: {
    parentProjectFloorData: Object
  },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showValuesetForm: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      tab: null,
      project: {
        value: null,
        text: null
      },
      tower: {
        value: null,
        text: null
      },
      floorType: {
        value: null,
        text: null
      },
      floorCode: null,
      floorId: 0,
      floorName: null,
      start_date_end_date: null,
      startDate: null,
      endDate: null,
      version: null,
      approvalStatus: null,
      sequence: null
    };
  },
  mounted() {
    if (this.parentProjectFloorData) {
      this.fillDataFromParent();
  } else { this.editMode = true }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditProjectFloor();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.floorId });
        }
      }
    });
  },
  methods: {
    fillDataFromParent() {
      this.project = {
        value: this.parentProjectFloorData.project_id,
        text: this.parentProjectFloorData.project_name
      };
      this.tower = {
        value: this.parentProjectFloorData.project_tower_id,
        text: this.parentProjectFloorData.tower_name
      };
      this.floorType = {
        value: this.parentProjectFloorData.floor_type_vset,
        text: this.parentProjectFloorData.project_floor_type
      };
      this.floorCode = this.parentProjectFloorData.floor_code;
      this.floorId = this.parentProjectFloorData.project_floor_id;
      this.floorName = this.parentProjectFloorData.floor_name;
      
      this.version = this.parentProjectFloorData.version;
      this.approvalStatus = this.parentProjectFloorData.approval_status;
      this.sequence = this.parentProjectFloorData.sequence;
      this.startDate = this.parentProjectFloorData.start_date;
      this.endDate = this.parentProjectFloorData.end_date;
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'DefineProject') {
        return (this.childName = 'Define Project');
      } else if (tab === 'ProjectTowers') {
        return (this.childName = 'Project Towers');
      }
    },
    addEditProjectFloor() {
      const payload = {
        project_tower_id: this.tower.value,
        start_date: this.startDate,
        end_date: this.endDate,

        floor_code: this.floorCode,
        project_floor_id: this.floorId,
        floor_name: this.floorName,
        floor_type_vset: this.floorType.value,
        sequence: this.sequence
      };
      this.loader = true;
      this.$store
        .dispatch('projects/addEditProjectsFloor', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('updateProjectFloorList');
            const result = response.data.data;
            this.editMode = false;
            this.floorId = result.floor_id;
            this.approvalStatus = result.approval_status;
            this.version = result.version;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.startDate
      );
    },
    hideModalComponent(flag, component) {
      if (component === 'ProjectTowers' && !this.project.text) {
        return alert(appStrings.DEPENDENTVSETMSG.PRJMASTERPRJMSG);
      }
      this.showModalComponent = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    projectDetails(item) {
      this.showModalComponent = false;
      this.project = {
        value: item.project_id,
        text: item.project_name
      };
      this.tower = {
        value: null,
        text: null
      };
    },
    towerDetails(item) {
      this.showModalComponent = false;
      this.tower = {
        value: item.tower_id,
        text: item.tower_name
      };
    },
    openValueSet() {
      this.showValuesetForm = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: 'FLOOR_TYPE_VSET',
          multiFlag: false
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(vsetName) {
      this.floorType = {
        text: vsetName.value_meaning,
        value: vsetName.value_code
      };
      this.showValuesetForm = false;
    },
    closeValueSetModal() {
      this.showValuesetForm = false;
    },
    clearVsetValues(){
      this.floorType = {
        text: null,
        value:null
      };
    }
    
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
